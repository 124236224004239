import {
  ADMIN_ROUTES,
  DASHBOARD,
  MY_LOCKER,
  GROUP_ADMIN_ROUTES,
} from "../../routes/constants";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { getInviteData, signUp } from "../../services/apiActions";

import Apple from "../../components/LoginApple";
import Cookies from "js-cookie";
import Facebook from "../../components/LoginFacebook";
import Google from "../../components/LoginGoogle";
import Loader from "../../components/Loader";
import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { setUserData } from "../../redux/actions/userActions";
import { ADMIN_ROLES, PUBLIC_ROLES } from "../../roleConstants";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      loading: true,
      submitting: true,
    };

    const self = this;
    getInviteData(Cookies.get("inviteKey"), (response) => {
      self.setState({
        displayName: response.data.displayName,
        email: response.data.email,
        loading: false,
        submitting: false,
      });
    });

    this.googleLoginSuccess = this.googleLoginSuccess.bind(this);
    this.fbLoginSuccess = this.fbLoginSuccess.bind(this);
    this.microsoftLoginSuccess = this.microsoftLoginSuccess.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    var url = window.location.href;

    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    if (params.code) {
      this.appleLoginSuccess(params.code);
    }
  }

  appleLoginSuccess(code) {
    const self = this;
    signUp(
      {
        accessToken: code,
        provider: "apple",
        inviteKey: Cookies.get("inviteKey"),
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  googleLoginSuccess(response) {
    const self = this;

    signUp(
      {
        accessToken: response.tokenId,
        inviteKey: Cookies.get("inviteKey"),
        provider: "google",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  fbLoginSuccess(response) {
    const self = this;
    signUp(
      {
        accessToken: response.accessToken,
        inviteKey: Cookies.get("inviteKey"),
        provider: "facebook",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  microsoftLoginSuccess(response) {
    const self = this;

    signUp(
      {
        accessToken: response.accessToken,
        inviteKey: Cookies.get("inviteKey"),
        provider: "microsoft",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  handleValidSubmit(event, formValues) {
    const self = this;
    var providerKey = [self.state.email, formValues.password].join(";");
    signUp(
      {
        accessToken: providerKey,
        inviteKey: Cookies.get("inviteKey"),
        provider: "kindkatch",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  render() {
    if (!!this.props.apiKey) {
      if (this.props.userRoles.includes(ADMIN_ROLES.ADMINISTRATOR)) {
        return <Redirect push to={ADMIN_ROUTES.CHARITIES} />;
      } else if (
        this.props.userRoles.includes(PUBLIC_ROLES.GROUP_ADMINISTRATOR)
      ) {
        return <Redirect push to={GROUP_ADMIN_ROUTES.SENT_REPORT} />;
      } else {
        if (
          this.props.userRoles.includes(PUBLIC_ROLES.ADMINISTRATOR) ||
          this.props.userRoles.includes(PUBLIC_ROLES.CONTENT_REVIEWER)
        ) {
          return <Redirect push to={DASHBOARD} />;
        }
        return <Redirect push to={MY_LOCKER} />;
      }
    }

    return (
      <React.Fragment>
        <div className="text-center mt-4" />
        <Card>
          <CardHeader>
            <h2>Account Setup</h2>
          </CardHeader>
          <CardBody>
            <p className="lead">
              Welcome {this.state.displayName}! Let’s create your log-in. Choose
              a password below OR pick an authentication provider from the list
              and follow the directions from there. Having issues here? Email
              your organization admin or drop a line to{" "}
              <a href="mailto:cody@kindkatch.com">cody@kindkatch.com</a>.
            </p>
            <div className="m-sm-4">
              <AvForm model={{}} onValidSubmit={this.handleValidSubmit}>
                <Row>
                  <Col>
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      value={this.state.email}
                      readOnly
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    The password must be at least 6 characters long and include
                    at least one number, one uppercase letter, one lowercase
                    letter, and one symbol.
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your password",
                        },
                        pattern: {
                          value:
                            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@;$%^&*-]).{6,}$",
                          errorMessage:
                            "Your password does not meet the minimum criteria",
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Your password must be between 6 and 64 characters",
                        },
                        maxLength: {
                          value: 64,
                          errorMessage:
                            "Your password must be between 6 and 64 characters",
                        },
                      }}
                    />
                  </Col>
                </Row>
                <FormGroup className="form-group d-flex justify-content-end">
                  <Button
                    className="mr-1 mb-1"
                    disabled={this.state.submitting}
                  >
                    Sign In
                  </Button>
                </FormGroup>
              </AvForm>
            </div>
            <div className="m-sm-4">
              <div className="text-center">
                <p>OR</p>
              </div>
            </div>
            <div className="m-sm-4">
              <div className="text-center">
                <Google successCallback={this.googleLoginSuccess} />
                <Apple
                  redirectURI={process.env.REACT_APP_APPLE_SIGNUP_REDIRECT}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (store) => ({
    apiKey: store.user.apiKey,
    userRoles: store.user.userRoles,
  }),
  (dispatch) => {
    return {
      setUserData: (displayName, userRoles, apiKey) => {
        dispatch(setUserData(displayName, userRoles, apiKey));
      },
    };
  }
)(SignUp);
