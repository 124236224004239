import { sendRequest } from "../apiService";
import store from "../../redux/store";
import { setDirty, setAdmins } from "../../redux/actions/adminsActions";

export function getAdminRoles(callback) {
  sendRequest({
    request: {
      resource: "/administration/users/roles/",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}
export function inviteAdmin(admin, callback) {
  sendRequest({
    request: {
      resource: "/administration/users/",
      method: "post",
    },
    data: admin,
    success: (response) => {
      store.dispatch(setDirty(true));
      callback(response);
    },
  });
}
export function getAdmins(callback) {
  //if (store.getState().admins.isDirty) {
  sendRequest({
    request: {
      resource: "/administration/users/",
      method: "get",
    },
    data: null,
    success: (response) => {
      store.dispatch(setAdmins(response.data));
      store.dispatch(setDirty(false));
      if (callback) callback(response);
    },
  });
  //}
}
export function getAdmin(adminId, callback) {
  sendRequest({
    request: {
      resource: `/administration/users/${adminId}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}
export function updateAdmin(adminId, admin, callback) {
  sendRequest({
    request: {
      resource: `/administration/users/${adminId}`,
      method: "put",
    },
    data: admin,
    success: (response) => {
      store.dispatch(setDirty(true));
      callback(response);
    },
  });
}

//template stuff
//-------------------------------------

//requests
export function getAdminMediaRequestTemplates(callback) {
  sendRequest({
    request: {
      resource: "/administration/media/requests/templates/store",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getAdminMediaRequestTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/requests/templates/store/${id}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function createAdminMediaRequestTemplate(data, callback) {
  sendRequest({
    request: {
      resource: "/administration/media/requests/templates/store",
      method: "post",
    },
    data: data,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function updateAdminMediaRequestTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/requests/templates/store/${id}`,
      method: "put",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function deleteAdminMediaRequestTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/requests/templates/store/${id}`,
      method: "delete",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCharityMediaRequestTemplates(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/requests/templates/charities/${id}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function copyAdminMediaRequestTemplates(id, data, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/requests/templates/charities/${id}`,
      method: "post",
    },
    data: data,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

//shares
export function getAdminMediaShareTemplates(callback) {
  sendRequest({
    request: {
      resource: "/administration/media/shares/templates/store",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getAdminMediaShareTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/shares/templates/store/${id}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function createAdminMediaShareTemplate(data, callback) {
  sendRequest({
    request: {
      resource: "/administration/media/shares/templates/store",
      method: "post",
    },
    data: data,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function updateAdminMediaShareTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/shares/templates/store/${id}`,
      method: "put",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function deleteAdminMediaShareTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/shares/templates/store/${id}`,
      method: "delete",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCharityMediaShareTemplates(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/shares/templates/charities/${id}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function copyAdminMediaShareTemplates(id, data, callback) {
  sendRequest({
    request: {
      resource: `/administration/media/shares/templates/charities/${id}`,
      method: "post",
    },
    data: data,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

// group administartion
export function getGroupAdministrators(callback) {
  sendRequest({
    request: {
      resource: "/administration/group-administrators",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function createGroupAdministrator(data, callback) {
  sendRequest({
    request: {
      resource: "/administration/group-administrators",
      method: "post",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function getGroupAdministrator(id, callback) {
  sendRequest({
    request: {
      resource: `/administration/group-administrators/${id}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateGroupAdministrator(id, data, callback) {
  sendRequest({
    request: {
      resource: `/administration/group-administrators/${id}`,
      method: "put",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function getGroupAdministratorOrganizations(id, callback) {
  if (!id) {
    id = "00000000-0000-0000-0000-000000000000";
  }
  sendRequest({
    request: {
      resource: `/administration/group-administrators/${id}/organizations`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}
