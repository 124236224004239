export const ADMIN_ROLES = { ADMINISTRATOR: "System Administrator" };

export const PUBLIC_ROLES = {
  ADMINISTRATOR: "Administrator",
  CONTENT_CREATOR: "Content Creator",
  CONTENT_REVIEWER: "Content Manager",
  GROUP_ADMINISTRATOR: "Group Administrator",
};

export const PUBLIC_MANAGEMENT_ROLES = {
  ADMINISTRATOR: "Administrator",
  CONTENT_CREATOR: "Content Creator",
};
